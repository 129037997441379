import { KeyValue } from "@angular/common";
import { Component, Input, OnChanges } from "@angular/core";
import { PricingModel } from "@common/ADAPT.Common.Model/embed/pricing-model";
import { UserType, UserTypeExtensions } from "@common/ADAPT.Common.Model/embed/user-type";
import { Connection, ConnectionBreezeModel } from "@common/ADAPT.Common.Model/organisation/connection";
import { ConnectionType } from "@common/ADAPT.Common.Model/organisation/connection-type";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { AdaptClientConfiguration, AdaptProject, AdaptProjectLabel } from "@common/configuration/adapt-client-configuration";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Component({
    selector: "adapt-display-pricing-breakdown",
    templateUrl: "./display-pricing-breakdown.component.html",
})
export class DisplayPricingBreakdownComponent extends BaseComponent implements OnChanges {
    @Input() public pricingModel?: PricingModel;
    @Input() public organisation?: Organisation;

    public readonly UserTypeExtensions = UserTypeExtensions;

    public pricingModelUserCounts = new Map<UserType, number>();

    public projectLabel: string;

    constructor(private commonDataService: CommonDataService) {
        super();

        // this can also be used on nimbus
        const isNimbus = AdaptClientConfiguration.AdaptProjectName === AdaptProject.Nimbus;
        const nimbusProject = AdaptClientConfiguration.EmbedApiBaseUri === AdaptClientConfiguration.AltoApiBaseUri
            ? AdaptProject.Alto
            : AdaptProject.Cumulus;
        this.projectLabel = isNimbus
            ? AdaptProjectLabel[nimbusProject]
            : AdaptClientConfiguration.AdaptProjectLabel;
    }

    public ngOnChanges() {
        let connections$: Observable<Connection[]>;
        if (this.organisation) {
            const predicate = new MethodologyPredicate<Connection>("organisationId", "==", this.organisation.organisationId);
            connections$ = this.commonDataService.getByPredicate(ConnectionBreezeModel, predicate);
        } else {
            connections$ = this.commonDataService.getAll(ConnectionBreezeModel);
        }

        const pricingModelUserTypes = this.pricingModel
            ? this.pricingModel.pricingModelUsers.map((i) => i.userType)
            : [];
        const excludedUserTypes = [UserType.None, UserType.Coach];
        const excludedConnectionTypes = [ConnectionType.Coach];
        this.pricingModelUserCounts.clear();
        connections$.pipe(
            map((connections) => connections.filter((conn) => conn.isActive() &&
                pricingModelUserTypes.includes(conn.userType) &&
                !excludedUserTypes.includes(conn.userType) &&
                !excludedConnectionTypes.includes(conn.connectionType))),
        ).subscribe((connections) => {
            for (const connection of connections) {
                const count = this.pricingModelUserCounts.get(connection.userType) ?? 0;
                this.pricingModelUserCounts.set(connection.userType, count + 1);
            }
        });
    }

    public orderByUserOrdinal(a: KeyValue<UserType, number>, b: KeyValue<UserType, number>) {
        return UserTypeExtensions.priceOrdinal(a.key) - UserTypeExtensions.priceOrdinal(b.key);
    }

    public price(userType: UserType) {
        const pricingModel = this.getPricingModelForUserType(userType);
        return pricingModel?.monthlyFeeDollars;
    }

    public subtotal(userType: UserType, value: number) {
        const pricingModel = this.getPricingModelForUserType(userType);
        return pricingModel
            ? pricingModel!.monthlyFeeDollars * value
            : 0;
    }

    public get basePlatformFee() {
        return (this.pricingModel?.monthlyFeeDollars ?? 0) + (this.organisation?.account?.monthlyFeeDollars ?? 0);
    }

    public get discount() {
        return Array.from(this.pricingModelUserCounts.entries()).reduce((acc, [userType, count]) => {
            return acc + this.subtotal(userType, count);
        }, 0) + this.basePlatformFee - this.total;
    }

    public get total() {
        return this.organisation?.account?.extensions.monthlySubscriptionCost ?? 0;
    }

    private getPricingModelForUserType(userType: UserType) {
        const pricingModel = this.pricingModel?.pricingModelUsers.find((pmu) => pmu.userType === userType);
        return pricingModel;
    }
}
