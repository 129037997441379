<table id="breakdown-table">
    <thead>
        <tr>
            <th></th>
            <th class="fw-normal px-2">
                <!-- still want to keep the column without pricing model users as I don't want to add logics to evaluate if to show
                 subtotal in 2nd or 3rd column -->
                <ng-container *ngIf="pricingModelUserCounts.size > 0">
                    # of users
                </ng-container>
            </th>
            <th class="fw-normal">Subtotal</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let countMap of pricingModelUserCounts | keyvalue: orderByUserOrdinal">
            <td>{{UserTypeExtensions.singularLabel(countMap.key)}} (${{price(countMap.key)}}*)</td>
            <td class="text-end px-2">{{countMap.value}}</td>
            <td class="text-end">${{subtotal(countMap.key, countMap.value)}}</td>
        </tr>
        <tr *ngIf="basePlatformFee > 0">
            <td>{{projectLabel}} platform fee</td>
            <td class="text-end px-2"></td>
            <td class="text-end">${{basePlatformFee}}</td>
        </tr>
        <tr *ngIf="discount > 0">
            <td>Annual subscription discount</td>
            <td class="text-end px-2"></td>
            <td class="text-end">${{discount}}</td>
        </tr>
    </tbody>
    <tfoot>
        <tr class="border-top border-secondary">
            <td colspan="2"><strong>Total (ex. GST)</strong></td>
            <td class="text-end"><strong>${{total}}</strong></td>
        </tr>
    </tfoot>
</table>
<p *ngIf="pricingModelUserCounts.size > 0">
    <small>* user prices are per month</small>
</p>
