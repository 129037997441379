import { Injectable, Injector } from "@angular/core";
import { ServiceUri } from "@common/configuration/service-uri";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { IBreezeModel } from "@common/lib/data/breeze-model.interface";
import { IBreezeParameter } from "@common/lib/data/breeze-parameter.interface";
import { IBreezeNamedParams, IBreezeQueryOptions } from "@common/lib/data/breeze-query-options.interface";
import { EntityPersistentService } from "@common/lib/data/entity-persistent.service";
import { BreezeQueryError, IBreezeExecuteQueryError } from "@common/lib/data/errors/breeze-query-error";
import { BreezeSaveError, IBreezeSaveChangesError } from "@common/lib/data/errors/breeze-save-error";
import { IMethodologyPredicate, MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { AdaptError } from "@common/lib/error-handler/adapt-error";
import { FunctionUtilities } from "@common/lib/utilities/function-utilities";
import { ObjectUtilities } from "@common/lib/utilities/object-utilities";
import { AfterInitialisationAsync } from "@common/service/after-initialisation.decorator";
import { BaseInitialisationService } from "@common/service/base-initialisation.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { DataProperty, Entity, EntityKey, EntityManager, EntityQuery, EntityType, MergeStrategy, MetadataStore, QueryResult, SaveOptions, SaveResult } from "breeze-client";
import { enableSaveQueuing, QueuedSaveFailedError } from "breeze-client/mixin-save-queuing";
import { lastValueFrom, ReplaySubject } from "rxjs";
import { ArrayUtilities } from "../utilities/array-utilities";
import { NumberUtilities } from "../utilities/number-utilities";
import { IBreezeService } from "./breeze-service.interface";
import { EntityCountHelper, IUseLocal } from "./entity-count-helper";
import { QueryUtilities } from "./query-utilities";

@Injectable()
export class BreezeService extends BaseInitialisationService implements IBreezeService {
    public static modelRegistrationOverride: ((metadataStore: MetadataStore, model: IBreezeModel) => void);

    private static organisationParam?: IBreezeParameter<number>;
    private static saveTagParameters: IBreezeParameter<any>[] = [];

    private static globalServiceInstanceId = 0;

    private static readonly SavePromiseKey = "BREEZE_SERVICE_SAVE_REQUEST_KEY";
    private entityManager: EntityManager;
    private promiseCache: { [requestKey: string]: Promise<any> } = {};
    private useLocal: IUseLocal = {};

    public readonly dataCacheCleared$ = new ReplaySubject<void>();

    private readonly serviceInstanceId: number;
    private readonly entityCountHelper: EntityCountHelper;

    // entities that we consider detached for the purposes of saving, fetching uncommitted entities, has changes, etc
    // this is so we can create entities for things like filters that should never be saved
    private transientEntities: IBreezeEntity[] = [];

    public constructor(
        protected injector: Injector,
        private entityPersistentService: EntityPersistentService,
        private parentBreezeService?: BreezeService,
    ) {
        super();
        this.serviceInstanceId = BreezeService.globalServiceInstanceId++;

        if (this.parentBreezeService) {
            this.entityManager = this.parentBreezeService.rawManager.createEmptyCopy();
            this.entityCountHelper = new EntityCountHelper(this.entityManager, this.useLocal, this.promiseToInitialiseModelParameters.bind(this), this.getQueryFailedFunction);
            return;
        }

        this.entityManager = new EntityManager({
            serviceName: ServiceUri.BreezeServiceUri,
            metadataStore: new MetadataStore(),
        });

        enableSaveQueuing(this.entityManager);

        MethodologyPredicate.log = this.log;

        this.entityCountHelper = new EntityCountHelper(this.entityManager, this.useLocal, this.promiseToInitialiseModelParameters.bind(this), this.getQueryFailedFunction);
    }

    public static setOrganisationIdInvoker(orgIdInvoker: IBreezeParameter<number>) {
        BreezeService.organisationParam = orgIdInvoker;
        BreezeService.addSaveTagParameter(BreezeService.organisationParam);
    }

    public static addSaveTagParameter<T extends string | number>(definition: IBreezeParameter<T>) {
        BreezeService.saveTagParameters.push(definition);
    }

    protected initialisationActions() {
        if (this.parentBreezeService) {
            return [];
        }

        return [
            this.entityManager.metadataStore.fetchMetadata(this.entityManager.dataService)
                .then(() => this.configureEntityPersistenceService())
                .then(() => this.configureBreezeEntityManager()),
        ];
    }

    public get breezeEntityManager() {
        return this.entityManager;
    }

    public createTransientInstance() {
        return new BreezeService(
            this.injector,
            this.entityPersistentService,
            this,
        );
    }

    /**
     * Should ONLY be used internally to this class OR by tests attempting to mock
     */
    public get rawManager() {
        return this.entityManager;
    }

    public registerModel<T extends IBreezeEntity>(model: IBreezeModel<T>) {
        if (this.entityPersistentService.isModelRegistered(model.toType)) {
            return; // model already register - registering it for the second times will cause Manager.metadataStore.registerEntityTypeCtor error
        }

        if (!model.params) {
            model.params = [];
        }

        if (BreezeService.modelRegistrationOverride) {
            BreezeService.modelRegistrationOverride(this.entityManager.metadataStore, model);
        }

        if (model.propertyDisplayNameOverrides) {
            const entityType = this.entityManager.metadataStore.getAsEntityType(model.toType);
            if (entityType) {
                model.propertyDisplayNameOverrides.forEach((displayName, propertyName) => {
                    const propertyType = entityType.getProperty(propertyName);
                    if (propertyType) {
                        propertyType.displayName = displayName;
                    } else {
                        throw new AdaptError(`Invalid property type for ${model.toType}.${propertyName}`);
                    }
                });
            } else {
                throw new AdaptError(`Invalid entity type for ${model.toType} detected`);
            }
        }

        if (model.useOrganisationParam && BreezeService.organisationParam) {
            // Is it an error if useOrganisationParam is specified and organisationParam isn't set?
            // Historical behaviour has been just not to use the param (e.g. for models which
            // have been recycled in Nimbus and Cumulus)
            // We probably should just extend the models in Cumulus to have useOrganisationParam
            // but that's a refactoring effort for another day
            model.params.push(BreezeService.organisationParam);
        }

        this.entityManager.metadataStore.registerEntityTypeCtor(model.toType, model.register!, model.initialize);

        if (model.validators) {
            for (const [propertyName, validator] of Object.entries(model.validators)) {
                const entType = this.entityManager.metadataStore.getAsEntityType(model.toType);

                if (!entType) {
                    this.logErrorMessage(`No entity type ${model.toType} while attempting to register validator`);
                    return;
                }

                if (propertyName.startsWith("entity")) {
                    entType.validators.push(validator);
                } else {
                    const property = entType.getProperty(propertyName);

                    if (!property) {
                        this.logErrorMessage(`Cannot register validator to property ${propertyName} of type ${model.toType}`);
                        return;
                    }

                    property.validators.push(validator);
                }
            }
        }

        this.entityPersistentService.modelRegistered(model.toType, model);
    }

    public hasChanges() {
        this.detachAllTransientEntities();
        const hasBreezeChanges = this.entityManager.hasChanges();
        this.attachAllTransientEntities();

        return hasBreezeChanges;
    }

    @AfterInitialisationAsync
    public promiseToGetAll<T extends IBreezeEntity>(model: IBreezeModel<T>, forceRemote: boolean = false) {
        return this.promiseToGetWithOptions(model, model.identifier, { forceRemote });
    }

    @AfterInitialisationAsync
    public promiseToGetCountByPredicate<T extends IBreezeEntity>(model: IBreezeModel, predicate: IMethodologyPredicate<T>) {
        return this.entityCountHelper.getCountByPredicate(model, predicate);
    }

    @AfterInitialisationAsync
    public async promiseToGetById<T extends IBreezeEntity>(model: IBreezeModel<T>, id: number, forceRemote: boolean = false): Promise<T | undefined> {
        if (!id) {
            const message = `No id passed to promiseToGetById for model ${model.identifier}`;
            this.logWarningMessage(message);
            this.throwNewError(message);
        }

        if (id < NumberUtilities.MinInt32 || id > NumberUtilities.MaxInt32) {
            // id can only be int32 or query will fail - return undefined as you will never find anything with this id
            return Promise.resolve(undefined);
        }

        const self = this;
        const requestKey = model.singularName + id;
        const requestDescription = `[${model.singularName} Entity ${id}]`;

        // use promiseCache if an existing server request is pending
        if (this.promiseCache[requestKey] !== undefined) {
            return this.promiseCache[requestKey].then((result) => {
                this.logSuccessMessage(`Retrieved ${requestDescription} from cache`, result);
                return result;
            });
        }

        if (!forceRemote) {
            return getLocalEntity();
        }

        return getServerEntity();

        function getLocalEntity() {
            const entity = self.entityManager.getEntityByKey(model.toType, id) as T;

            // check the server if not found locally
            if (!entity) {
                return getServerEntity();
            }

            // don't go to server if its new
            if (entity.entityAspect.entityState.isAdded()) {
                return success();
            }

            // hide soft marked-for-delete entities
            if (entity.entityAspect.entityState.isDeleted()) {
                return Promise.resolve(undefined);
            }

            // get from server if nav properties not loaded
            if (!entity.isComplete) {
                return getServerEntity();
            }

            return success();

            function success() {
                self.logSuccessMessage(`Retrieved ${requestDescription} from local data source`, entity);
                return Promise.resolve(entity);
            }
        }

        function getServerEntity() {
            const params = {};

            self.promiseCache[requestKey] = self.promiseToInitialiseModelParameters(model, params)
                .then(getFromServer)
                .then(cleanUpAndReturnResult)
                .catch(handleError);

            return self.promiseCache[requestKey];

            function getFromServer() {
                const et = self.entityManager.metadataStore.getAsEntityType(model.toType);
                const ek = new EntityKey(et, id);
                let query = EntityQuery.fromEntityKey(ek)
                    .withParameters(params);
                if (model.navProperty) {
                    query = query.expand(model.navProperty);
                }

                return self.entityManager.executeQuery(query)
                    .then(querySucceeded)
                    .catch(self.getQueryFailedFunction(requestDescription));

                function querySucceeded(data: QueryResult) {
                    const entity = ArrayUtilities.getSingleFromArray(data.results as T[]);

                    if (!entity) {
                        self.logInfoMessage(`Unable to locate ${requestDescription}`);

                        return undefined;
                    }

                    // capture that nav properties loaded
                    self.initializeEntity(model, true)(entity);
                    self.logSuccessMessage(`Retrieved ${requestDescription} from remote data source`, entity);

                    return entity;
                }
            }

            function cleanUpAndReturnResult(result: any) {
                delete self.promiseCache[requestKey];

                return result;
            }

            function handleError(error: BreezeQueryError) {
                delete self.promiseCache[requestKey];
                return Promise.reject(error);
            }
        }
    }

    @AfterInitialisationAsync
    public promiseToGetByPredicate<T extends IBreezeEntity>(model: IBreezeModel<T>, predicate: IMethodologyPredicate<T> | undefined, forceRemote: boolean = false): Promise<T[]> {
        const requestKey = model.identifier + (predicate?.getKey() ?? "");
        return this.promiseToGetWithOptions(model, requestKey, {
            predicate,
            forceRemote,
        });
    }

    @AfterInitialisationAsync
    public promiseToGetTopByPredicate<T extends IBreezeEntity>(model: IBreezeModel<T>, top: number, predicate: IMethodologyPredicate<T> | undefined, forceRemote: boolean = false): Promise<T[]> {
        const requestKey = `top${top}${model.identifier}${(predicate?.getKey() ?? "")}`;
        return this.promiseToGetWithOptions(model, requestKey, {
            top,
            predicate,
            forceRemote,
        });
    }

    /**
     *  Call this to clean up a breeze service such that there are no entities in the cache, and dependant services are also empty.
     *  This should ONLY be called when transient breeze service instances have been created => use promiseToClearCache in all other cases.
     */
    @AfterInitialisationAsync
    public cleanupInstance() {
        this.logInfoMessage("Deleting instance");
        const unfinishedPromises = Object.keys(this.promiseCache).map((key) => this.promiseCache[key]);

        return Promise.all(unfinishedPromises)
            .catch(FunctionUtilities.noop) // Suppress any errors
            .finally(() => {
                // clear will detach all entities from the manager which then allows everything to get garbage collected.
                // We want to run this even if the promises fail - hence finally
                this.entityManager.clear();

                this.promiseCache = {};
                this.useLocal = {};
                this.transientEntities = [];
                this.entityCountHelper.clearCache();
            });
    }

    public clearQueryCacheForRequestKey(requestKey: string) {
        this.useLocal[requestKey] = false;
    }

    /**
     * Call this to clear the cache, create a new entity manager and initialise all dependant services.
     * This should be called when a user is logging out, switching organisation, etc
     */
    @AfterInitialisationAsync
    public async promiseToClearCache() {
        if (this.entityManager) {
            const unfinishedPromises = Object.keys(this.promiseCache).map((key) => this.promiseCache[key]);

            if (unfinishedPromises.length > 0) {
                const previousManager = this.entityManager;
                this.entityManager = previousManager.createEmptyCopy();
                this.entityPersistentService.entityManager = this.entityManager;

                await Promise.all(unfinishedPromises)
                    .catch(FunctionUtilities.noop) // Suppress any errors
                    // clear will detach all entities from the manager which then allows
                    // everything to get garbage collected.
                    // We want to run this even if the promises fail - hence finally
                    .finally(() => previousManager.clear());
            } else {
                this.entityManager.clear();
            }
        }

        this.promiseCache = {};
        this.useLocal = {};
        this.transientEntities = [];
        this.entityCountHelper.clearCache();
        this.entityCountHelper.setEntityManager(this.entityManager, this.useLocal);

        this.entityPersistentService.initialise(); // re-initialise after user/organisation change
        this.dataCacheCleared$.next();
    }

    @AfterInitialisationAsync
    public async promiseToGetWithOptions<T extends IBreezeEntity>(model: IBreezeModel<T>, requestKey: string, options: IBreezeQueryOptions<T>) {
        const getOptions = Object.assign({}, model, options);

        getOptions.namedParams = getOptions.namedParams || {};
        const requestDescription = `${getOptions.pluralName} [${requestKey}]`;
        const self = this;

        // use promiseCache if an existing server request is pending
        if (this.promiseCache[requestKey] !== undefined) {
            return this.promiseCache[requestKey].then((result) => {
                this.logSuccessMessage(`Retrieved ${requestDescription} from cache`, result.length);
                return result;
            }) as Promise<T[]>;
        }

        // if there is an existing request to get all is being processed, then just wait for that to finish first
        if (this.promiseCache[model.identifier] !== undefined) {
            await this.promiseCache[model.identifier];
        }

        if (!getOptions.forceRemote
            && (this.useLocal[requestKey]
                || getOptions.forceLocal
                || (!getOptions.matchKeyOnly && this.useLocal[model.identifier])
                || (getOptions.encompassingKey && this.useLocal[getOptions.encompassingKey]))) {
            return this.promiseToInitialiseModelParameters(model, getOptions.namedParams)
                .then(getFromLocal);
        } else {
            this.promiseCache[requestKey] = this.promiseToInitialiseModelParameters(getOptions, getOptions.namedParams)
                .then(getFromServer)
                .then(getFromLocalIfNotForceRemote)
                .then(cleanUpAndReturnResult)
                .catch(handleError);

            return this.promiseCache[requestKey] as Promise<T[]>;
        }

        // This will keep the result consistent when getting from remote data source and local breeze cache.
        function getFromLocalIfNotForceRemote(result: T[]) {
            const serverResultLength = result.length;

            if (getOptions.forceRemote) {
                // if forceRemote, simply return result server
                // This will also make query with predicate:
                //  [personMethodologyUser.userName==walter@lospollos.com]
                // which gets processed in the server works.
                return result;
            } else {
                // Integrate entities from remote data source with changes locally
                // e.g. entities with EntityState isAddedModifiedOrDeleted
                const localResults = getFromLocal();
                let localResultLength = localResults.length;

                if (localResultLength !== serverResultLength) {
                    for (const localResult of localResults) {
                        if (localResult.entityAspect.entityState.isAdded()) {
                            // exclude count of all newly added entities
                            localResultLength--;
                        } else if (localResult.entityAspect.entityState.isDeleted()) {
                            // include count of all newly delete entities
                            localResultLength++;
                        }
                    }
                }

                if (localResultLength !== serverResultLength
                    // Don't log the error if activeOnly is set. e.g. for SCU
                    // server has the positions primed for both person1 and person2
                    // whereas client side only prime for persons in the team.
                    // So active filter will basically filter out all persons not in the team
                    // (which is what we want but will fail this consistency check).
                    // Not really a warning especially when entities are primed through nav properties and this will happen quite a bit.
                    && !(getOptions.namedParams && getOptions.namedParams.activeOnly)) {
                    self.logInfoMessage(
                        `Inconsistent number of records returned from server (${serverResultLength}) and local (${localResultLength}) for request: ${requestDescription}`);
                    // need to clean up entities from local cache that's no longer returned from server
                    const obsoleteEntities = localResults.filter((entity) => !result.includes(entity));
                    for (const obsoleteEntity of obsoleteEntities) {
                        ArrayUtilities.removeElementFromArray(obsoleteEntity, localResults);
                        self.entityManager.detachEntity(obsoleteEntity);
                    }
                }

                return localResults;
            }
        }

        function cleanUpAndReturnResult(result: T[]) {
            self.useLocal[requestKey] = true;
            delete self.promiseCache[requestKey];

            const key = options.postRequestEncompassingKey?.(result);
            if (key) {
                self.useLocal[key] = true;
            }

            return result;
        }

        function handleError(error: any) {
            if (error.notCurrentManager) {
                self.logInfoMessage(error.message);

                return Promise.resolve([]); // let the promise finish and get on with the entity manager clean up.
            } else {
                delete self.promiseCache[requestKey];

                return Promise.reject(error);
            }
        }

        function getFromServer(): Promise<T[]> {
            const query = buildQuery(true);

            return query.execute()
                .then(querySucceeded, self.getQueryFailedFunction(requestDescription)); // queryFailed here so it won't pick up error thrown from querySucceeded

            function querySucceeded(data: QueryResult): any | T[] {
                if (data.entityManager !== self.entityManager) {
                    const error = new Error(self.buildErrorLogMessage(`Result from previous manager - to be discarded: ${requestDescription}`));

                    (error as any).notCurrentManager = true;

                    return Promise.reject(error);
                }

                self.logSuccessMessage(`Retrieved ${requestDescription} from remote data source`, data.results.length);

                self.entityCountHelper.saveEntityCountToCache(
                    model,
                    self.entityCountHelper.getEntityCacheCountKey(model, options.predicate, options.namedParams),
                    data.results.length);

                // capture that navProperties were loaded
                data.results.forEach(self.initializeEntity(model, !getOptions.isPartial));

                return data.results as T[];
            }
        }

        function getFromLocal(): T[] {
            const query = buildQuery(false);
            let results = query.executeLocally() as T[];

            results = QueryUtilities.processQueryOptions(results, getOptions);
            self.logSuccessMessage(`Retrieved ${requestDescription} from local data source`, results.length);

            self.entityCountHelper.saveEntityCountToCache(
                model,
                self.entityCountHelper.getEntityCacheCountKey(model, options.predicate, options.namedParams),
                results.length);

            return results;
        }

        function buildQuery(remote: boolean) {
            let query = EntityQuery
                .from(getOptions.source!)
                .toType(getOptions.toType)
                .using(self.entityManager);

            if (getOptions.select && remote) {
                query = query.select(getOptions.select);
            }

            if (getOptions.navProperty) {
                query = query.expand(getOptions.navProperty);
            }

            // as we re-query results from the server using a local query, there is no point in sending an order by request to the server
            // for queries that aren't fetching using skip or top, it justs slows the server down unnecessarily
            if (getOptions.orderBy && (!remote || (getOptions.skip || getOptions.top))) {
                query = query.orderBy(getOptions.orderBy);
            }

            if (getOptions.namedParams) {
                query = query.withParameters(getOptions.namedParams);
            }

            if (getOptions.predicate) {
                const entityType = query._getFromEntityType(self.entityManager.metadataStore);
                const breezePredicate = (getOptions as IBreezeQueryOptions<T>).predicate!.createBreezePredicate(entityType);

                if (breezePredicate) {
                    // only add predicate to the where clause if the predicate is valid - otherwise, it will throw error
                    // log the predicate for easier debug
                    const predicateJson = breezePredicate.toJSONExt({ entityType, toNameOnServer: true });
                    self.logDebugMessage(`Breeze predicate ${requestDescription}`, predicateJson);
                    query = query.where(breezePredicate);
                } else {
                    self.logWarningMessage(
                        "Invalid predicate for request (most probably after a reload where breeze is reloaded or invalid key or value) "
                        + requestDescription
                        + " predicate: "
                        + getOptions.predicate.getKey());
                }
            }

            if (getOptions.skip && remote) {
                query = query.skip(getOptions.skip);
            }

            if (getOptions.top && remote) {
                query = query.take(getOptions.top);
            }

            if (getOptions.skipMerge) {
                query = query.using(MergeStrategy.SkipMerge);
            }

            return query;
        }
    }

    /**
     * Create a breeze entity of the specified model.
     * @param model
     * @param data
     */
    @AfterInitialisationAsync
    public promiseToCreate<T extends IBreezeEntity>(model: IBreezeModel<T>, data?: Partial<T>) {
        const entity = this.entityManager.createEntity(model.toType, data) as T;
        this.initializeEntity(model, false)(entity);

        return Promise.resolve(entity);
    }

    @AfterInitialisationAsync
    public async promiseToRemove<T extends IBreezeEntity>(entity?: T) {
        if (!entity || entity.entityAspect.entityState.isDetached()) {
            return undefined;
        }

        this.entityPersistentService.entityRemoved(entity);

        // for modified (ie existing) objects force the entity back to an unchanged state prior to delete
        // this is so that when the entity gets sent to the server, breeze doesn't get confused by an entity that has changed prior to being deleted
        if (entity.entityAspect.entityState.isModified()) {
            entity.entityAspect.rejectChanges();
        }

        entity.entityAspect.setDeleted();

        // return the entity to allow for saving of the removal
        return entity;
    }

    /**
     * Save changes to breeze entities
     * @param {Array} entities Optional: array of specific entities to save.
     *      If not set will save all entities with pending changes.
     *      Will not save dependent entities so use with caution.
     * @returns {Promise} Resolves when save completed.
     */
    @AfterInitialisationAsync
    public promiseToSave(entities?: IBreezeEntity[]): Promise<SaveResult> {
        this.detachAllTransientEntities();
        const cascadeDeletedEntities = this.getDiscardCascadeDeletedEntities();

        this.entityCountHelper.clearCountCacheForEntities(entities);
        const self = this;

        this.promiseCache[BreezeService.SavePromiseKey] = Promise.resolve(this.initialiseParameters(BreezeService.saveTagParameters))
            .then(saveChanges)
            .then(saveSucceeded)
            .catch(saveFailed)
            .finally(postSaveCleanup);

        return this.promiseCache[BreezeService.SavePromiseKey];

        function saveChanges(tagObject: any) {
            const saveOptions = new SaveOptions({
                resourceName: "SaveChanges",
                tag: tagObject,
            });

            if (entities) {
                entities.forEach((entity) => self.rejectUnchangedModifiedEntity(entity));
                entities = entities.filter(excludeUnchangedOrDetached);
            } else {
                // going to save all from entity manager - go through them all and reject unchanged
                const changes = self.entityManager.getChanges();
                changes.forEach((changedEntity) => self.rejectUnchangedModifiedEntity(changedEntity as IBreezeEntity));
            }

            return self.entityManager.saveChanges(entities, saveOptions);

            function excludeUnchangedOrDetached(entity: IBreezeEntity) {
                return !(entity.entityAspect.entityState.isUnchanged()
                    || entity.entityAspect.entityState.isDetached());
            }
        }

        function saveSucceeded(result: SaveResult) {
            self.logSuccessMessage("Saved data", result);
            if (cascadeDeletedEntities.length > 0) {
                // need to check if entity already detached as entityManager will reject if attempting to detach detached entity
                cascadeDeletedEntities.forEach((entity) => !entity.entityAspect.entityState.isDetached() && self.entityManager.detachEntity(entity));
            }

            self.entityPersistentService.entitiesSaved(result.entities as IBreezeEntity[]);

            return result;
        }

        function saveFailed(error: IBreezeSaveChangesError | QueuedSaveFailedError) {
            return Promise.reject(new BreezeSaveError(error));
        }

        function postSaveCleanup() {
            self.attachAllTransientEntities();
            delete self.promiseCache[BreezeService.SavePromiseKey];
        }
    }

    public clearCountCacheForEntities(entities?: IBreezeEntity[]) {
        this.entityCountHelper.clearCountCacheForEntities(entities);
    }

    @AfterInitialisationAsync
    public promiseToCancel() {
        this.entityPersistentService.clear();
        this.detachAllTransientEntities();

        if (this.entityManager.hasChanges()) {
            this.entityManager.rejectChanges();
            this.logSuccessMessage("Cancelled changes");
        }

        this.attachAllTransientEntities();

        // Not strictly necessary for function, but keep to maintain
        // backwards compatibility with legacy (direct) calls which
        // expect this to be a promise.
        return Promise.resolve();
    }

    @AfterInitialisationAsync
    public promiseToRejectChanges(entities: IBreezeEntity | IBreezeEntity[]) {
        let allEntities: IBreezeEntity[];
        if (this.isEntity(entities)) {
            allEntities = [entities as IBreezeEntity];
        } else if (Array.isArray(entities)) {
            allEntities = entities.filter(this.isEntity);
        } else {
            allEntities = [];
        }

        allEntities.forEach((entity: IBreezeEntity) => {
            this.entityPersistentService.entityRemoved(entity);

            if (!entity.entityAspect.entityState.isDetached()) {
                entity.entityAspect.rejectChanges();
            }
        });

        return Promise.resolve();
    }

    public entitiesAreValid(): boolean {
        this.detachAllTransientEntities();
        const entities = this.entityManager.getChanges();
        this.attachAllTransientEntities();
        return entities.every((e) => this.isValidEntity(e));
    }

    /**
     *  Gets changed reference entities, including entities that have been deleted.
     *  This should be used in lieu of the navigation property which does not contain deleted entities.
     */
    public getChangedReferenceEntities<T extends IBreezeEntity>(referenceModel: IBreezeModel<T>, foreignKeyField: string, foreignKeyValue: any): T[] {
        if (this.entityManager.hasChanges()) {
            const referenceType = this.entityManager.metadataStore.getAsEntityType(referenceModel.toType);
            const changes = this.entityManager.getChanges(referenceType) as T[];

            return changes.filter((entity: T) => entity[foreignKeyField] === foreignKeyValue);
        }

        return [];
    }

    /**
     * Get all uncommitted changes of the type as specified by model
     * @param {any} model - The model with 'toType'
     * @returns {array} Array of entities which are changed but uncommitted
     */
    public getUncommittedChanges<T extends IBreezeEntity>(model?: IBreezeModel<T>) {
        let entityType: EntityType | undefined;
        if (model && model.toType) {
            entityType = this.entityManager.metadataStore.getAsEntityType(model.toType);
        }

        this.detachAllTransientEntities();
        const changes = entityType
            ? this.entityManager.getChanges(entityType)
            : this.entityManager.getChanges();
        this.attachAllTransientEntities();

        return changes as T[];
    }

    public rejectUnchangedModifiedEntity(entity: IBreezeEntity) {
        let isValueChanged = false;

        if (ObjectUtilities.isObject(entity.entityAspect)
            && ObjectUtilities.isObject(entity.entityAspect.originalValues)
            && entity.entityAspect.entityState.isModified()) {

            for (const [key, value] of Object.entries(entity.entityAspect.originalValues)) {
                if (value !== entity[key]) {
                    isValueChanged = true;
                }
            }

            if (!isValueChanged) {
                entity.entityAspect.rejectChanges();
            }
        }
    }

    public isEntity(entity: any): entity is IBreezeEntity {
        return ObjectUtilities.isObject(entity)
            && ObjectUtilities.isObject(entity.entityAspect)
            && !Array.isArray(entity.entityAspect);
    }

    /**
     * Returns whether the entity in question is one of our 'transient' detached entities (that we never save, and doesn't affect hasChanges, etc)
     * @param entity
     */
    public isTransientEntity(entity: IBreezeEntity) {
        return this.transientEntities.includes(entity);
    }

    public getLocalModelEntityById<T extends IBreezeEntity>(model: IBreezeModel<T>, id: number) {
        // if the model is not defined, then we definitely don't have any entities associated with it
        if (!model) {
            this.throwNewError("Undefined model passed to getLocalModelEntityById");
        }

        return this.entityManager.getEntityByKey(model.toType, id) as T | undefined;
    }

    public hasLocalEntity<T extends IBreezeEntity>(model: IBreezeModel<T>, predicate: (e: T) => boolean) {
        return this.entityManager.getEntities(model.toType)
            .some(predicate);
    }

    public detachEntityFromBreeze(entity: IBreezeEntity) {
        const affectedChangedEntities: Entity[] = [];
        const changedEntities = this.entityManager.getChanges();
        for (const changedEntity of changedEntities) {
            if (changedEntity.getProperty && changedEntity.entityAspect) { // getProperty is possibly undefined
                // For each changed entity, check if there is any nav properties which is the entity to be detached.
                // Note: cannot use Object.keys or Object.values as breeze entity properties are not ownProperty
                const navPropertyNames = changedEntity.entityType.navigationProperties.map((i) => i.name);
                if (navPropertyNames.find((navPropertyName) => changedEntity.getProperty!(navPropertyName) === entity)) {
                    affectedChangedEntities.push(changedEntity);
                }
            }
        }

        // reject changes for affected entities before detaching
        affectedChangedEntities.forEach((affectedEntity) => affectedEntity.entityAspect.rejectChanges());
        this.entityManager.detachEntity(entity);
    }

    public isValidEntity(entity: IBreezeEntity | Entity): boolean {
        // ensure the validation is up do date, as some entity validation functions only get called on save
        // this forces them all to run again, so we get a true picture of the validation state
        const isValid = entity.entityAspect.validateEntity();

        if (!isValid) {
            const errors = entity.entityAspect.getValidationErrors();
            this.logInfoMessage("Invalid Entity with errors:", errors);
        }

        return isValid;
    }

    // needs to be a promise so that we can chain the result
    private async promiseToInitialiseModelParameters<T extends IBreezeEntity>(model: IBreezeModel<T>, dest: IBreezeNamedParams) {
        if (model.params) {
            await this.initialiseParameters(model.params, dest);
        }
    }

    private async initialiseParameters(paramDefinitions: IBreezeParameter<any>[], dest: IBreezeNamedParams = {}) {
        for (const definition of paramDefinitions) {
            if (definition.waitObservable) {
                await lastValueFrom(definition.waitObservable(this.injector));
            }

            const value = definition.invoker(this.injector);
            if (!definition.valueIsValid(value)) {
                throw new AdaptError(`Invalid tag value for ${definition.paramName} detected: ${value}`);
            }
            dest[definition.paramName] = value;
        }

        return dest;
    }

    private initializeEntity<T extends IBreezeEntity>(model: IBreezeModel<T>, isComplete: boolean) {
        return (entity: T) => {
            // capture that nav properties have been loaded
            entity.isComplete = isComplete;

            // re-run initialize in case it ran previously without any nav properties
            if (FunctionUtilities.isFunction(model.initialize)) {
                model.initialize(entity);
            }
        };
    }

    private configureEntityPersistenceService() {
        this.entityPersistentService.breezeService = this;

        // need to pass in a function to return a value here to workaround circular dependency between breeze and org data service
        if (BreezeService.organisationParam) {
            this.entityPersistentService.getOrganisationIdFunction = () => BreezeService.organisationParam!.invoker(this.injector);
        } else {
            this.entityPersistentService.getOrganisationIdFunction = undefined;
        }

        this.entityPersistentService.entityManager = this.entityManager;
        this.entityPersistentService.getCommonDialogServiceFunction = () => this.injector.get(AdaptCommonDialogService);
        this.entityPersistentService.initialise();
    }

    private configureBreezeEntityManager() {
        for (const type of this.entityManager.metadataStore.getEntityTypes()) {
            if (type instanceof EntityType) {
                this.entityManager.metadataStore.setEntityTypeForResourceName(type.shortName, type);
            }
        }
    }

    private getDiscardCascadeDeletedEntities() {
        const changedEntities = this.entityManager.getChanges() as IBreezeEntity[];
        const cascadeDeletedEntities: IBreezeEntity[] = [];
        for (const entity of changedEntities) {
            if (isEntityWithForeignKeyProperties(entity)) {
                // if newly added, changes won't be tracked in originalValues, so need to iterate through foreign keys to check for 0
                // and related type deleted
                if (entity.entityAspect.entityState.isAdded()) {
                    for (const foreignKey of entity.entityType.foreignKeyProperties) {
                        if (entity[foreignKey.name] === 0 &&
                            changedEntities.some((i) => foreignKey.relatedNavigationProperty?.entityType.name === i.entityType.name
                                && i.entityAspect.entityState.isDeleted())) {
                            // foreign key value is reset and the related entity type is deleted
                            // - rejectChanges will detach the added entity - so don't have to add it into
                            //   the return collection to be detach after save.
                            // - have to reject here or this incomplete entity will be attached
                            entity.entityAspect.rejectChanges();
                            break;
                        }
                    }
                } else if (entity.entityAspect.entityState.isModified()) {
                    const changedKeys = Object.keys(entity.entityAspect.originalValues);
                    for (const key of changedKeys) {
                        if (isForeignKey(key, entity.entityType.foreignKeyProperties) && !entity[key] &&
                            changedEntities.some((i) => i[key] === entity.entityAspect.originalValues[key] && i.entityAspect.entityState.isDeleted())) {
                            // breeze will reset the foreign key id to 0 if the principal entity is removed
                            entity.entityAspect.rejectChanges();
                            cascadeDeletedEntities.push(entity);
                            break;
                        }
                    }
                }
            }
        }

        return cascadeDeletedEntities;

        function isEntityWithForeignKeyProperties(entity: IBreezeEntity) {
            return entity && entity.entityAspect &&
                entity.entityType && entity.entityType.foreignKeyProperties;
        }

        function isForeignKey(key: string, foreignKeys: DataProperty[]) {
            return foreignKeys.some((foreignKey) => foreignKey.name === key);
        }
    }

    private detachAllTransientEntities() {
        for (const entity of this.transientEntities) {
            if (!entity.entityAspect.entityState.isDetached()) {
                this.entityManager.detachEntity(entity);
            }
        }
    }

    private attachAllTransientEntities() {
        for (const entity of this.transientEntities) {
            if (entity.entityAspect.entityState.isDetached()) {
                this.entityManager.attachEntity(entity);
            }
        }
    }
    private getQueryFailedFunction(queryName: string) {
        return (error: IBreezeExecuteQueryError) => Promise.reject(new BreezeQueryError(queryName, error));
    }

    private logErrorMessage(message: string, data?: any) {
        this.log.error(this.buildErrorLogMessage(message), data);
    }

    private logWarningMessage(message: string, data?: any) {
        this.log.warn(this.buildErrorLogMessage(message), data);
    }

    private logInfoMessage(message: string, data?: any) {
        this.log.info(this.buildErrorLogMessage(message), data);
    }

    private logSuccessMessage(message: string, data?: any) {
        this.log.success(this.buildErrorLogMessage(message), data);
    }

    private logDebugMessage(message: string, data?: any) {
        this.log.debug(this.buildErrorLogMessage(message), data);
    }

    private throwNewError(message: string) {
        throw new AdaptError(this.buildErrorLogMessage(message));
    }

    private buildErrorLogMessage(message: string) {
        return `InstanceId: ${this.serviceInstanceId}: ${message}`;
    }
}
