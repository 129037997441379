import { Injectable, Injector, NgZone, ProviderToken } from "@angular/core";
import { ServiceUri } from "@common/configuration/service-uri";
import { Logger } from "@common/lib/logger/logger";
import { HubConnectionBuilder, ILogger, LogLevel } from "@microsoft/signalr";
import { merge, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { FunctionUtilities } from "../utilities/function-utilities";
import { ConnectionEvent } from "./connection-state/connection-event.enum";
import { ISignalRConnectionContext, SignalRConnectionContext } from "./connection-state/signalr-connection-context";
import { ISignalRHubImplementation } from "./signalr-hub-implementation.interface";

export interface IHubNameToImplementation {
    [hubName: string]: ISignalRHubImplementation;
}

export type IAccessTokenFactory = () => Promise<string>;

export interface IHubNameToImplementationId {
    [hubName: string]: ProviderToken<ISignalRHubImplementation>;
}

@Injectable({
    providedIn: "root",
})
export class SignalRService {
    private static readonly HelloEvent = "hello";

    private static hubNamesAndImplementationConstructors: IHubNameToImplementationId = {};
    private static accessTokenProvider?: IAccessTokenFactory;

    private readonly log = Logger.getLogger("SignalRService");
    private readonly internalLog = Logger.getLogger("@microsoft/signalr");

    private connectionContexts: ISignalRConnectionContext[] = [];

    private connectionStateChanged = new Subject<ConnectionEvent>();
    public connectionStateChanged$ = this.connectionStateChanged.asObservable();

    public constructor(
        private injector: Injector,
        private zone: NgZone,
    ) {
    }

    /**
     * Register a hub to use with SignalR. This hub must implement ISignalRHubImplementation (or extend SignalRHub)
     * and be registered in Angular as a service in order to work successfully.
     * @param hubName
     * @param hubImplementationId
     */
    public static registerHubImplementation(hubName: string, hubImplementationId: ProviderToken<ISignalRHubImplementation>) {
        this.hubNamesAndImplementationConstructors[hubName] = hubImplementationId;
    }

    public static registerAccessTokenFactory(accessTokenFactory: IAccessTokenFactory) {
        this.accessTokenProvider = accessTokenFactory;
    }

    public setup(hubNameToImplementations?: IHubNameToImplementation) {
        // allow providing hub implementations manually for tests
        if (!hubNameToImplementations) {
            hubNameToImplementations = {};
            const hubNames = Object.keys(SignalRService.hubNamesAndImplementationConstructors);
            for (const hubName of hubNames) {
                hubNameToImplementations[hubName] = this.injector.get(SignalRService.hubNamesAndImplementationConstructors[hubName]);
            }
        }

        // Provide default implementation for hello event so that the OnConnected event gets fired on the server
        // See
        // https://docs.microsoft.com/en-us/aspnet/signalr/overview/guide-to-the-api/hubs-api-guide-javascript-client#how-to-establish-a-connection
        // for more details
        this.connectionContexts = this.createHubConnections(Object.keys(hubNameToImplementations));
        this.connectionContexts.forEach((hub) => this.registerHelloCallback(hub));

        // service connection state for all hubs
        merge(...this.connectionContexts.map((hub) => hub.connectionStateChanged$)).pipe(
            debounceTime(100),
            // don't want to update the state again if it hasn't changed.
            // since this event is combining the events for all hubs,
            // e.g. if they all disconnect there will be multiple ConnectionEvent.Reconnecting being raised
            // causing multiple lost connection dialogs
            distinctUntilChanged(),
        ).subscribe(this.connectionStateChanged);

        this.registerHubProxiesAndHandlers(hubNameToImplementations);
    }

    public promiseToConnect() {
        return Promise.all(this.connectionContexts.map((c) => c.promiseToConnect()));
    }

    public disconnect() {
        for (const connectionContext of this.connectionContexts) {
            connectionContext.disconnect();
        }
    }

    // protected so we can override it
    protected createHubConnection(hubName: string) {
        return new HubConnectionBuilder()
            .withUrl(`${ServiceUri.SignalRServiceBaseUri}/${hubName}`, {
                accessTokenFactory: SignalRService.accessTokenProvider,
                // all the transports seem to work well (at least locally)
                // transport: HttpTransportType.WebSockets,
            })
            .withAutomaticReconnect()
            .configureLogging(this.getHubLogger())
            .build();
    }

    private createHubConnections(hubNames: string[]) {
        return hubNames.map((hubName) => {
            const hubConnection = this.createHubConnection(hubName);
            return new SignalRConnectionContext(this.injector, hubConnection, hubName);
        });
    }

    private getHubLogger(): ILogger {
        return {
            log: (logLevel, message) => {
                const logMapping: Record<LogLevel, (message: string) => void> = {
                    [LogLevel.Trace]: FunctionUtilities.noop,
                    [LogLevel.Debug]: this.internalLog.debug,
                    [LogLevel.Information]: this.internalLog.info,
                    [LogLevel.Warning]: this.internalLog.warn,
                    [LogLevel.Error]: this.internalLog.warn,
                    [LogLevel.Critical]: this.internalLog.error,
                    [LogLevel.None]: FunctionUtilities.noop,
                };

                logMapping[logLevel](message);
            },
        };
    }

    private registerHubProxiesAndHandlers(hubNameAndImplementationConstructors: IHubNameToImplementation) {
        for (const [hubName, hubImplementation] of Object.entries(hubNameAndImplementationConstructors)) {
            const connectionContext = this.getHubConnectionContext(hubName);
            this.registerHubImplementation(connectionContext, hubImplementation);
        }
    }

    private getHubConnectionContext(hubName: string) {
        const hub = this.connectionContexts.find((connectionContext) => connectionContext.hubName === hubName);
        if (!hub) {
            throw new Error(`SignalR Hub "${hubName}" was not registered in config`);
        }

        return hub;
    }

    private registerHubImplementation(connectionContext: ISignalRConnectionContext, implementation: ISignalRHubImplementation) {
        implementation.setConnectionContext(connectionContext);
        implementation.initialise();
        implementation.configureConnection?.(connectionContext);

        const eventHandlers = implementation.getHandlingMethods();
        for (const [eventName, handler] of Object.entries(eventHandlers)) {
            this.onHubEvent(connectionContext, eventName, handler);
        }
    }

    private registerHelloCallback(connectionContext: ISignalRConnectionContext) {
        this.onHubEvent(connectionContext, SignalRService.HelloEvent, () => this.log.info(`Hello from ${connectionContext.hubName}`));
    }

    private onHubEvent(connectionContext: ISignalRConnectionContext, event: string, callback: (...args: any[]) => void) {
        connectionContext.connection.on(event, (...args: any[]) => {
            this.zone.run(() => {
                this.log.info(`Handling ${connectionContext.hubName} ${event} with`, args);
                callback(...args);
            });
        });
    }
}
