import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { Account, SubscriptionStatus } from "@common/ADAPT.Common.Model/account/account";
import { BillingPeriod, BillingPeriodButtons } from "@common/ADAPT.Common.Model/account/account-extensions";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { AdaptClientConfiguration, AdaptProject } from "@common/configuration/adapt-client-configuration";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { ICardPartialDetails } from "@common/payment-processing/card-partial-details.interface";
import { PaymentProcessingService } from "@common/payment-processing/payment-processing.service";
import { IUpdateCreditCardInput, UpdateCreditCardDetailsComponent } from "@common/payment-processing/update-credit-card-details/update-credit-card-details.component";
import { UserService } from "@common/user/user.service";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { DirectorySharedService } from "@org-common/lib/directory-shared/directory-shared.service";
import { catchError, EMPTY, of, switchMap, tap } from "rxjs";
import { finalize } from "rxjs/operators";
import { OrganisationService } from "../../organisation.service";

export interface ISetInitialSubscriptionDialogData {
    account: Account;
    disallowCancel?: boolean;
}

@Component({
    selector: "adapt-set-initial-subscription-dialog",
    templateUrl: "./set-initial-subscription-dialog.component.html",
})
export class SetInitialSubscriptionDialogComponent extends BaseDialogComponent<Account, SubscriptionStatus> implements OnInit {
    public readonly dialogName = "SetInitialSubscriptionDialogComponent";
    public readonly StartSubscriptionArticle = AdaptClientConfiguration.AdaptProjectName === AdaptProject.Alto
        ? ImplementationKitArticle.StartSubscription
        : ImplementationKitArticle.StartSubscriptionEmbed;
    public readonly BillingPeriodButtons = BillingPeriodButtons;
    public readonly BillingPeriod = BillingPeriod;
    public readonly ProductLabel = AdaptClientConfiguration.AdaptProjectLabel;
    public readonly Now = new Date().getTime();

    public cardSetCorrectly = false;
    public selectedBillingPeriod: BillingPeriod[];

    @ViewChild(UpdateCreditCardDetailsComponent) public updateCreditCardDetailsComponent?: UpdateCreditCardDetailsComponent;

    public creditCardInput: IUpdateCreditCardInput;
    public cardDetails?: ICardPartialDetails;
    public cardDetailsLoading = true;
    public organisation?: Organisation;

    public isIntroPage = true;
    public account: Account;
    private originalStatus: SubscriptionStatus;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public dialogData: ISetInitialSubscriptionDialogData,
        private commonDataService: CommonDataService,
        private paymentService: PaymentProcessingService,
        private userService: UserService,
        private directoryService: DirectorySharedService,
        organisationService: OrganisationService,
    ) {
        super();
        this.account = dialogData.account;
        this.originalStatus = this.account.status;
        this.creditCardInput = {
            cardDetails: undefined,
            organisationIdentifier: {
                organisationId: this.account.organisationId,
                eulaToken: undefined,
            },
        };

        this.selectedBillingPeriod = [this.account.billingPeriod];
        organisationService.promiseToGetOrganisation()
            .then((org) => {
                this.organisation = org;
                // prime invoices so it can figure out the paidUntilDate for the account
                return paymentService.getInvoices(org?.organisationId);
            });
    }

    public ngOnInit() {
        this.getCardDetails().subscribe();
    }

    public cancel() {
        this.commonDataService.rejectChanges(this.account)
            .subscribe(() => super.cancel());
    }

    @Autobind
    public saveAndClose() {
        if (!this.cardDetails && !this.updateCreditCardDetailsComponent) {
            this.setErrorMessage("Updating credit card failed. Please contact us.");
            return EMPTY;
        }

        // save account first before update credit card or update of account from server when CreateConfirmIntent in saveCreditCard
        // will be overwritten by the outdated account changes
        return this.paymentService.saveEntities(this.account).pipe(
            switchMap(() => this.updateCreditCardDetailsComponent?.saveCreditCard() ?? of(undefined)),
            switchMap(() => this.paymentService.commenceSubscription(this.account.organisationId)),
            tap(() => super.resolve(this.originalStatus)),
            catchError((e) => {
                this.setErrorMessage(ErrorHandlingUtilities.getHttpResponseMessage(e));
                return EMPTY;
            }),
        );
    }

    private getCardDetails() {
        this.setErrorMessage(undefined);
        this.cardDetailsLoading = true;

        return this.paymentService.getCreditCardPartialDetails(this.creditCardInput.organisationIdentifier)
            .pipe(
                switchMap(async (cardDetails) => {
                    if (!this.account.contactEmail || !this.account.contactName) {
                        if (this.userService.currentPerson) {
                            // need to prime person contacts there is no email
                            await this.directoryService.promiseToGetContactDetailsByPersonId(this.userService.currentPerson.personId);
                            this.account.contactName = this.userService.currentPerson.fullName;
                            this.account.contactEmail = this.userService.currentPerson.getLoginEmail()?.value;
                        }
                    }

                    return cardDetails;
                }),
                tap((cardDetails) => {
                    this.cardDetails = cardDetails;
                    this.cardSetCorrectly = !!cardDetails;
                }),
                catchError((errorMessage: string) => {
                    this.setErrorMessage(errorMessage);
                    return EMPTY;
                }),
                finalize(() => this.cardDetailsLoading = false),
            );
    }

    public onSelect(billingPeriod: BillingPeriod) {
        this.account.billingPeriod = billingPeriod;
    }
}
