import { HttpErrorResponse } from "@angular/common/http";
import { Component, Injector, OnInit, ViewChild } from "@angular/core";
import { PricingModel } from "@common/ADAPT.Common.Model/embed/pricing-model";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { IdentityService } from "@common/identity/identity.service";
import { IIdentityViewModel } from "@common/identity/identity-view-model.interface";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { UserService } from "@common/user/user.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { FeaturesService } from "@org-common/lib/features/features.service";
import { AccountService } from "@org-common/lib/organisation/account/account.service";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { managePeoplePageRoute } from "@org-common/lib/user-management/manage-people-page/manage-people-page.component";
import { ValueChangedEvent } from "devextreme/ui/text_box";
import { ValidationCallbackData } from "devextreme/ui/validation_rules";
import { DxValidationGroupComponent } from "devextreme-angular";
import { lastValueFrom } from "rxjs";

interface IPayload extends IIdentityViewModel {
    organisationName: string;
    urlIdentifier: string;
    coachPersonId: number;
    firstName: string;
    lastName: string;
    emailAddress: string;
}

@Component({
    selector: "adapt-subscribe-organisation-page",
    templateUrl: "./subscribe-organisation-page.component.html",
    styleUrls: ["./subscribe-organisation-page.component.scss"],
})
export class SubscribeOrganisationPageComponent extends BaseRoutedComponent implements OnInit {
    @ViewChild("validationGroup") public validationGroup: DxValidationGroupComponent | undefined;
    public organisation: Organisation;
    public inProgress: boolean;
    public isDisabled = true;
    public error?: string;
    public duplicates: string[] = [];
    public leaderFirstName = "";
    public leaderLastName = "";
    public leaderEmail = "";

    public defaultPricingModel?: PricingModel;

    constructor(
        public featuresService: FeaturesService,
        public authService: IdentityService,
        public organisationService: OrganisationService,
        public commonDataService: CommonDataService,
        private userService: UserService,
        private accountService: AccountService,
        injector: Injector,
    ) {
        super(injector);

        this.organisation = new Organisation();
        this.organisation.name = "";
        this.organisation.urlIdentifier = "";

        this.inProgress = false;
    }

    public ngOnInit() {
        this.accountService.getPricingModels().pipe(
            this.takeUntilDestroyed(),
        ).subscribe((pricingModels) => {
            this.defaultPricingModel = pricingModels.find((m) => m.isDefault);
            this.notifyActivated();
        });
    }

    public orgNameChanged(event: ValueChangedEvent) {
        this.validateGroup();
        this.updateUrlIdentifier(event.value, event.previousValue);
    }

    private updateUrlIdentifier(newOrgName?: string, oldOrgName?: string) {
        if (this.organisation.urlIdentifier === this.convertToUrl(oldOrgName)) {
            // if they were matching before the last update
            if (newOrgName) {
                // update url id to match name
                this.organisation.urlIdentifier = this.convertToUrl(newOrgName);
            } else {
                // name has been deleted, update url id to match
                this.organisation.urlIdentifier = "";
            }
        }
    }

    private convertToUrl(orgName?: string) {
        // only allow lowercase alphanumerics
        orgName = orgName ? orgName : "";
        return orgName.toLowerCase()
            .replace(/[^a-z0-9]/g, "")
            .substring(0, 20);
    }

    @Autobind
    public async subscribe() {
        this.inProgress = true;
        this.error = undefined;

        const payload: IPayload = {
            organisationName: this.organisation.name,
            urlIdentifier: this.organisation.urlIdentifier,
            firstName: this.leaderFirstName,
            lastName: this.leaderLastName,
            emailAddress: this.leaderEmail,
            coachPersonId: this.userService.getCurrentPersonId()!, // this page has requireCoach(true) - current person must be coach to subscribe
        };

        try {
            await this.authService.registerOrganisation(payload);
            await this.organisationService.promiseToSwitchOrganisation({ organisationUrlIdentifier: this.organisation.urlIdentifier });
            await this.updateOrganisationLogo();
            await this.routeService.gotoControllerRoute(managePeoplePageRoute.id);
        } catch (e: any) {
            this.reportError(e, payload.urlIdentifier);
        } finally {
            this.inProgress = false;
        }
    }

    private async updateOrganisationLogo() {
        const newOrganisation = await this.organisationService.promiseToGetOrganisation();
        const imageIdentifier = this.organisation.imageIdentifier;

        this.organisation = newOrganisation!;
        this.organisation.imageIdentifier = imageIdentifier;

        await lastValueFrom(this.commonDataService.save());
        this.organisationService.notifyOrganisationImageIdChanged();
    }

    @Autobind
    private reportError(result: HttpErrorResponse, urlIdentifier: string) {
        this.error = ErrorHandlingUtilities.getHttpResponseMessage(result.error);
        if (this.error.includes("Portal URL is already in use")) {
            this.isDisabled = true;
            this.duplicates.push(urlIdentifier);
        }
    }

    @Autobind
    public validateDuplicates(e: ValidationCallbackData) {
        if (typeof e.value === "string") {
            return !this.duplicates.includes(e.value);
        }
    }

    public validateGroup() {
        this.isDisabled = !this.validationGroup?.instance.validate().isValid;
    }
}
